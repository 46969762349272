@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.wrapperGroupBtnsLine {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    display: none;
  }
}

.wrapperBtn {
  display: flex;
  gap: 4px;

  white-space: nowrap;
  width: 100%;
  max-width: 100%;

  & button {
    flex-shrink: 0;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
}

.wrapperTabsContent {
  margin: var(--vstack-spacing-sm) 0;

  @include tablet {
    margin: var(--vstack-spacing-md) 0;
  }

  @include desktop {
    margin: var(--vstack-spacing-lg) 0;
  }
}

.tabButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.justifyCenter {
  @include tablet {
    justify-content: center;
  }
}

.arrowBtn {
  cursor: pointer;
  transition: opacity 80ms ease-in-out;
}

.arrowHidden {
  opacity: 0;
  visibility: hidden;
}
