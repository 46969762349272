@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 32px;

  @include tablet {
    gap: 48px;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .wrapperFields {
    max-width: 100%;
    width: 100%;

    @include desktop {
      max-width: 540px;
    }
  }

  .wrapperBtns {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;

    button {
      width: 100%;
    }

    @include tablet {
      display: flex;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
    }
  }
}

.field {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .MuiInputLabel-root {
      color: var(--palette-gray-400);
    }

    .MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: var(--palette-gray-900);
    }

    .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend {
      background-color: var(--palette-gray-70) !important;
    }

    .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--palette-gray-150);
    }

    .MuiInputBase-root.MuiInputBase-adornedEnd {
      padding-inline-end: 16px !important;
    }

    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      box-shadow: none;
      border-width: 1px !important;
    }

    .MuiInputBase-root {
      background: transparent !important;
      height: 44px;
      border: 1px solid var(--palette-gray-150);

      &:focus,
      &:hover {
        background: var(--palette-white) !important;
        border-width: 1px !important;

        .MuiOutlinedInput-notchedOutline {
          box-shadow: none !important;
        }
      }
    }
  }
}

.resultField {
  :global {
    .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend {
      background-color: var(--palette-white) !important;
    }
  }
}

.errorMessage {
  color: var(--palette-red-600);
  margin-top: 16px;
}
