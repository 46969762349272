@import '@/styles/breakpoints.module';

.secondRowNavItem {
  --space-btw-dropdown-and-header: 12px;
  --height-header: 48px;
  white-space: nowrap;
  min-width: fit-content;
  padding-inline-end: 20px;

  @include desktop {
    padding-inline-end: 16px;
  }

  & > :first-child {
    padding: 0;
  }
}
