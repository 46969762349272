@import '@/styles/breakpoints.module';

.wrapperContent{
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include tablet{
    gap: 32px;
  }

  @include desktop{
    gap: 40px;
  }
}

.wrapperFields{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
