@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.modal {
  :global .MuiPaper-root {
    max-width: 800px !important;
    width: 100% !important;
    padding: 16px;
    @include tablet {
      padding: 24px;
    }
    @include desktop {
      padding: 40px;
    }
  }

  header {
    h1 {
      margin-inline-end: 33px;
    }

    p {
      margin-top: 8px;
      @include typography.bodyS_;
      @include tablet {
        @include typography.bodyM_;
      }
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.steps {
  margin-top: 12px;
  @include tablet {
    margin-top: 16px;
  }
}

.step {
  h3 {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--color-text-primary);
  }

  p {
    @include typography.bodyXS_;
    @include tablet {
      @include typography.bodyS_;
    }
  }

  .marker {
    border-radius: 12px !important;

    @include tablet {
      width: 44px !important;
      height: 44px !important;
    }
  }
}

.stepsTitle {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    display: block;
    width: 24px;
    height: auto;
  }
}

.actionButton {
  width: 100%;
}

.bottomWrapper {
  margin-top: 12px;

  @include tablet {
    margin-top: 16px;
  }

  @include tablet {
    display: flex;
    gap: 16px;
  }
}

.qrWrapper {
  display: none;

  @include tablet {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 12px;
    border-radius: 8px;
    background-color: var(--palette-gray-70);
    padding: 16px;
  }

  .qrCode {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }

  .qrText {
    text-align: start;
    color: var(--palette-black);
    @include typography.subheadingS_;
    @include desktop {
      @include typography.subheadingM_;
    }
    font-weight: var(--palette-font-weight-semibold);
  }
}

.helpWrapper {
  text-align: center;
  @include tablet {
    border-radius: 8px;
    background-color: var(--palette-gray-70);
    padding: 16px;
  }

  .supportButton {
    margin-top: 16px;
    @include tablet {
      white-space: nowrap;
    }
  }

  .helpText {
    border-radius: 8px;
    background-color: var(--palette-gray-70);
    color: var(--color-text-primary);
    padding: 12px;

    @include tablet {
      padding: 0;
      background-color: transparent;
    }

    br {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}
