@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.selectedProductLabel {
  padding: 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: var(--border-radius-nav-product-label);
  box-shadow: 0 0 0 1px var(--palette-primary-300) inset;
  margin-inline-end: 24px;
  color: var(--color-text-primary);
  @include typography.buttonS_;
  @include desktop {
    @include typography.buttonM_;
    margin-inline-end: 20px;
  }
  &:hover {
    box-shadow: 0 0 0 1px var(--palette-primary-700) inset;
  }
}
