.icon {
  margin-inline-end: 14px;

  & > * {
    color: var(--palette-gray-900);
    width: 24px;
    height: 24px;
  }
}

.title {
  display: flex;
  font-weight: var(--font-weight-nav-item);
  color: var(--color-text-primary) !important;
  margin-bottom: 2px;

  svg {
    color: var(--palette-primary-700);
    margin-inline-start: 2px;
    display: none;
  }
}

.description {
  white-space: normal;
  display: block;
}

.topLevelSubitem {
  cursor: pointer;
  transition: all 50ms ease-in-out;
  display: flex;
  align-items: center;
  max-width: 371px;
  min-width: 371px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-inline-end: 16px;
  padding-inline-start: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.active {
  background-color: var(--palette-white);
  border-radius: var(--palette-border-radius-m);

  .title svg {
    display: block;
  }

  &.hasSidebar {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      inset-inline-start: calc(100% - 5px);
      top: 50%;
      transform: translateY(-50%);
      background-image: url('arrow.svg');
      background-repeat: no-repeat;
      width: 24px;
      height: 26px;
      z-index: 1;
    }
  }
}

.topLevelSubitem:active {
  background-color: var(--palette-gray-50);
}
