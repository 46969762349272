@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.buttonsWrapper {
  border-radius: var(--palette-border-radius-s);
  padding: 4px;
  background-color: var(--palette-gray-700); /////////
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  overflow: auto;

  button {
    position: relative;
    width: 50%;
    min-width: 170px;
    padding: 6px 10px;
    color: var(--color-text-secondary);
    font-weight: var(--font-weight-btn);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    cursor: pointer;
    transition: color 0.2s ease;

    @include bodyM_;

    svg {
      margin-inline-end: 8px;
    }

    &:hover,
    &.active {
      color: var(--color-heading);
    }

    .label {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
    }

    .bubble {
      position: absolute;
      inset: 0;
      background-color: var(--palette-primary-700);
      border-radius: var(--palette-border-radius-s);
    }
  }

  @include tablet {
    width: 374px;
  }
}

.cardsLayout {
  display: grid;
  gap: 16px;
  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include desktop {
    gap: 12px;
  }
  @include large-desk {
    gap: 30px;
  }
}

.actionButton {
  width: 100%;
  @include tablet {
    width: unset;
  }
}

.spanUnderBtn {
  text-align: center;
  @include subheadingL_;
}

.disclaimer {
  text-align: center;
  margin-top: 12px;

  @include tablet {
    margin-top: 16px;
  }
}

.placeholder {
}
