@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.cookieShort {
  &:not(:last-child) {
    padding-bottom: 12px;
  }
}

.inner {
  display: grid;
  gap: 12px;

  .content {
    @include bodyXS_;
    @include tablet {
      @include bodyS_;
    }
  }

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  p {
    color: var(--color-text-primary);
  }
}
