@import '@/styles/breakpoints.module';

.telegram {
  --card-bg: var(--telegram-icon);
}

.facebook {
  --card-bg: var(--facebook-icon);
}

.twitter {
  --card-bg: var(--twitter-icon);
}

.instagram {
  --card-bg: var(--instagram-icon);
}

.discord {
  --card-bg: var(--discord-icon);
}

.reddit {
  --card-bg: var(--reddit-icon);
}

.youtube {
  --card-bg: var(--youtube-icon);
}

.linkedin {
  --card-bg: var(--linkedin-icon);
}

.tiktok {
  --card-bg: var(--tiktok-icon);
}

.itemWrapper {
  --twitter-icon: var(--palette-gray-900);
  --facebook-icon: linear-gradient(180deg, #17aafe 0%, #0165e1 100%);
  --instagram-icon: radial-gradient(
      179.06% 43.44% at -16.75% 7.2%,
      #3771c8 0%,
      #3771c8 12.8%,
      rgba(102, 0, 255, 0) 100%
    ),
    radial-gradient(92.18% 99.11% at 26.56% 107.7%, #ffdd55 0%, #ffdd55 10%, #ff543e 50%, #c837ab 100%);
  --tiktok-icon: var(--palette-gray-900);
  --linkedin-icon: #0967c3;
  --youtube-icon: #ff0402;
  --youtube-hover-icon: #f13a39;
  --reddit-icon: #ff6329;
  --discord-icon: #7c94ed;
  --telegram-icon: linear-gradient(180deg, #2ba9ec 0%, #1595d9 100%);

  --transition: all 0.1s ease-in-out;

  padding: 16px;
  border-radius: 12px;
  background: var(--palette-gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  transition: var(--transition);
  position: relative;
  overflow: hidden;

  @include tablet {
    padding: 32px;
    gap: 16px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    background: var(--card-bg);
    z-index: 0;
    opacity: 0;
    transition: var(--transition);
  }

  .iconWrapper,
  .linkTitle {
    z-index: 1;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .iconWrapper {
      &:before {
        opacity: 0;
      }
    }

    .linkTitle {
      color: var(--palette-white);
    }

    svg {
      transform: scale(1.5);
    }
  }

  .iconWrapper {
    border-radius: var(--palette-border-radius-m);
    width: 44px;
    height: 44px;
    position: relative;
    overflow: hidden;

    @include tablet {
      width: 56px;
      height: 56px;
    }

    &:before {
      content: '';
      background: var(--card-bg);
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      inset-inline-end: 0;
      bottom: 0;
      transition: var(--transition);
    }

    img,
    svg {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
      transition: transform 0.2s ease;
    }
  }

  .linkTitle {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--palette-gray-900);
  }
}
